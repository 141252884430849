<template>
  <div>
      <SecondNavBar/>    
      <ProfileForm @set-loading="setLoading"/>
      
      <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false" 
                
                ></loading>
      </div>
  </div>
</template>

<script>
import ProfileForm from '../../components/Profile/ProfileForm'
import SecondNavBar from '../../components/Clients/SecondNavBar'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
components:{
    ProfileForm,
    SecondNavBar,
     Loading
},
data(){
    return{
        isLoading: false
    }
},
methods:{
    setLoading(e){
        this.isLoading = e
    }
}

}
</script>

<style>
.green-underline{
    border-bottom:3px solid #b4d88c;
     padding: 3px 10px 6px 12px;
}
</style>